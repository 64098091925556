<template>
  <div class="main-top">
    <div class="top-bg">
      <img class="bg" :src="require(`@/assets/images/bg.png`)" alt="" />
      <div class="main-con">
        <h1>承诺达标合格证</h1>
        <div class="identifying">
          <img
            style="width: 22px; height: 28px"
            :src="require(`@/assets/images/ywym.png`)"
            alt=""
          />
          <span>一物一码</span>
          <img
            style="width: 26px; height: 23px"
            :src="require(`@/assets/images/smyz.png`)"
            alt=""
          />
          <span>扫码验真</span>
          <img
            style="width: 26px; height: 26px"
            :src="require(`@/assets/images/jjjh.png`)"
            alt=""
          />
          <span>拒绝假货</span>
        </div>
      </div>
    </div>
    <div class="describes-con">
      <div v-show="modluIndex == 1" class="list-item">
        <div class="info">
          <h1>作物种类</h1>
          <h1 v-if="form.productClassName">{{ form.productClassName }}</h1>
        </div>
        <div class="info">
          <h1>作物品种</h1>
          <h1>{{ form.productGenusName }}</h1>
        </div>
        <div class="info">
          <h1>种植面积</h1>
          <h1>{{ form.cultivatedArea }}</h1>
        </div>
        <div class="info">
          <h1>种植地块</h1>
          <h1>{{ form.parcelName }}</h1>
        </div>
        <div class="info">
          <h1>播种时间</h1>
          <h1>{{ form.seedingTime }}</h1>
        </div>
        <div class="info">
          <h1>播种方式</h1>
          <h1>{{ form.seedingMethod }}</h1>
        </div>
        <div v-if="form.image" class="infos">
          <h1>播种图片</h1>
          <div @click="seeImgChange(form)">
            <img style="width: 200px" :src="form.image" alt="" />
          </div>
        </div>
        <div v-if="form.video" class="infos">
          <h1>播种视频</h1>
          <video
            :autoplay="true"
            loop
            style="width: 100%; height: 200px"
            :src="form.video"
          ></video>
        </div>
      </div>
      <div v-show="modluIndex == 2" class="list-item">
        <div class="info">
          <h1>施肥日期</h1>
          <h1>{{ form.applyFertilizerDate }}</h1>
        </div>
        <div class="info">
          <h1>施肥类型</h1>
          <h1>{{ form.type }}</h1>
        </div>
        <div class="info">
          <h1>施肥品牌</h1>
          <h1>{{ form.brand }}</h1>
        </div>
        <div class="info">
          <h1>施肥方法</h1>
          <h1>{{ form.method }}</h1>
        </div>
        <div class="info">
          <h1>施肥位置</h1>
          <h1>{{ form.address }}</h1>
        </div>
        <div class="info">
          <h1>施肥人员</h1>
          <h1>{{ form.userName }}</h1>
        </div>
        <div class="info">
          <h1>施肥量</h1>
          <h1>{{ form.weight }}</h1>
        </div>
        <div v-if="form.image" class="infos">
          <h1>图片</h1>
          <div @click="seeImgChange(form)">
            <img style="width: 200px" :src="form.image" alt="" />
          </div>
        </div>
        <div v-if="form.video" class="infos">
          <h1>视频</h1>
          <video :src="form.video"></video>
        </div>
      </div>
      <div v-show="modluIndex == 3" class="list-item">
        <div class="info">
          <h1>收获日期</h1>
          <h1>{{ form.harvestDate }}</h1>
        </div>
        <div class="info">
          <h1>收获方式</h1>
          <h1>{{ form.method }}</h1>
        </div>
        <div class="info">
          <h1>收获量</h1>
          <h1>{{ form.harvestYield }}</h1>
        </div>
        <div class="info">
          <h1>收获地块</h1>
          <h1>{{ form.parcelName }}</h1>
        </div>
        <div class="info">
          <h1>收获人员</h1>
          <h1>{{ form.user }}</h1>
        </div>
        <div class="info">
          <h1>收获处理</h1>
          <h1>{{ form.dispose }}</h1>
        </div>
        <div v-if="form.image" class="infos">
          <h1>播种图片</h1>
          <div @click="seeImgChange(form)">
            <img style="width: 200px" :src="form.image" alt="" />
          </div>
        </div>
        <div v-if="form.video" class="infos">
          <h1>播种视频</h1>
          <video :src="form.video"></video>
        </div>
      </div>
      <div v-show="modluIndex == 4" class="list-item">
        <div class="info">
          <h1>监控地块</h1>
          <h1>{{ parcelName }}</h1>
        </div>
        <div class="info">
          <h1>监控设备</h1>
          <h1>{{ form.channelName }}</h1>
        </div>
        <div class="infos">
          <h1>实时监控</h1>
          <div
            @click="seeChange()"
            v-if="!seeShow"
            style="width: 100%; position: relative"
          >
            <img :src="require(`@/assets/images/newsimg.png`)" />
            <van-icon
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              "
              size="80"
              color="#fff"
              name="play-circle-o"
            />
          </div>
          <video v-else :src="video"></video>
        </div>
      </div>
      <div v-show="modluIndex == 5" class="list-item">
        <div class="info">
          <h1>地块名称</h1>
          <h1>{{ form.name }}</h1>
        </div>
        <div class="info">
          <h1>地块面积</h1>
          <h1>{{ form.area }}亩</h1>
        </div>
        <div class="info">
          <h1>地块长宽</h1>
          <h1>{{ form.length }}{{ form.wide }}</h1>
        </div>
        <div class="info">
          <h1>土壤结构</h1>
          <h1>{{ form.soilStructure }}</h1>
        </div>
        <div class="info">
          <h1>土壤PH值</h1>
          <h1>{{ form.soilPhOne }}</h1>
        </div>
        <div class="info">
          <h1>土壤氮</h1>
          <h1>{{ form.nitrogenOne }}</h1>
        </div>
        <div class="info">
          <h1>土壤磷</h1>
          <h1>{{ form.phosphorusOne }}</h1>
        </div>
        <div class="info">
          <h1>钾含量</h1>
          <h1>{{ form.potassiumOne }}</h1>
        </div>
        <div class="info">
          <h1>土壤温度</h1>
          <h1>{{ form.soilTemOne }}</h1>
        </div>
        <div class="info">
          <h1>土壤湿度</h1>
          <h1>{{ form.soilHumOne }}</h1>
        </div>
        <div class="info">
          <h1 class="d">土壤检测设备</h1>
          <h1>{{ form.soilName }}</h1>
        </div>
        <div v-if="form.parcelImage" class="infos">
          <h1>播种图片</h1>
          <div @click="seeImgChange(form)">
            <img style="width: 200px" :src="form.parcelImage" alt="" />
          </div>
        </div>
        <div v-if="form.parcelVideo" class="infos">
          <h1>播种视频</h1>
          <video :src="form.parcelVideo"></video>
        </div>
      </div>
      <div v-show="modluIndex == 6" class="list-item">
        <div v-for="item in list" :key="item.productId" class="list-con">
          <div class="top productName-con">
            <span v-if="item.productName">{{ item.productName }}</span>
          </div>
          <div class="main-con">
            <div class="content">
              <span>施用人员：{{ item.user }}</span>
              <span v-show="item.date"
                >施用时间：{{ item.date.substring(0, 10) }}</span
              >
            </div>
            <img :src="item.image" />
          </div>
        </div>
      </div>
      <div v-show="modluIndex == 7" class="list-item">
        <div class="info">
          <h1>监测地块</h1>
          <h1>{{ form.parcelName }}</h1>
        </div>
        <div class="info">
          <h1>温度</h1>
          <h1>{{ form.airTem }}</h1>
        </div>
        <div class="info">
          <h1>风度</h1>
          <h1>{{ form.windSpeed }}</h1>
        </div>
        <div class="info">
          <h1>风向</h1>
          <h1>{{ form.windDirection }}</h1>
        </div>
        <div class="info">
          <h1>累计雨量</h1>
          <h1>{{ form.grandTotalPrecipitation }}</h1>
        </div>
        <div class="info">
          <h1>PM值</h1>
          <h1>{{ form.pmTwoPointFive }}</h1>
        </div>
        <div class="info">
          <h1>光照</h1>
          <h1>{{ form.illuminance }}</h1>
        </div>
        <div class="info">
          <h1>湿度</h1>
          <h1>{{ form.relativeHum }}</h1>
        </div>
        <div class="info">
          <h1>气象监测设备</h1>
          <h1>{{ form.weatherName }}</h1>
        </div>
        <div v-if="form.weatherImage" class="infos">
          <h1>播种图片</h1>
          <div @click="seeImgChange(form)">
            <img style="width: 200px" :src="form.weatherImage" alt="" />
          </div>
        </div>
        <div v-if="form.weatherVideo" class="infos">
          <h1>播种视频</h1>
          <video :src="form.weatherVideo"></video>
        </div>
      </div>
      <div v-show="modluIndex == 8" class="list-item">
        <div class="info">
          <h1>种苗来源</h1>
          <h1>{{ form.source }}</h1>
        </div>
        <div class="info">
          <h1>种苗监控情况</h1>
          <h1>{{ form.health }}</h1>
        </div>
        <div class="info">
          <h1>种苗数量</h1>
          <h1>{{ form.count }}</h1>
        </div>

        <div v-if="form.image" class="infos">
          <h1>播苗图片</h1>
          <div @click="seeImgChange(form)">
            <img style="width: 200px" :src="form.image" alt="" />
          </div>
        </div>
        <div v-if="form.video" class="infos">
          <h1>播苗视频</h1>
          <video :src="form.video"></video>
        </div>
      </div>
      <div v-show="modluIndex == 10" class="list-item">
        <div class="info">
          <h1>灌溉日期</h1>
          <h1>{{ form.irrigateDate }}</h1>
        </div>
        <div class="info">
          <h1>灌溉方式</h1>
          <h1>{{ form.method }}</h1>
        </div>
        <div class="info">
          <h1>灌溉水源</h1>
          <h1>{{ form.sourceWater }}</h1>
        </div>
        <div class="info">
          <h1>灌溉开始时间</h1>
          <h1>{{ form.beginDate }}</h1>
        </div>
        <div class="info">
          <h1>灌溉结束时间</h1>
          <h1>{{ form.endDate }}</h1>
        </div>
        <div class="info">
          <h1>灌溉人员</h1>
          <h1>{{ form.user }}</h1>
        </div>
        <div class="info">
          <h1>灌溉地块</h1>
          <h1>{{ form.parcelName }}</h1>
        </div>

        <div v-if="form.image" class="infos">
          <h1>播苗图片</h1>
          <div @click="seeImgChange(form)">
            <img style="width: 200px" :src="form.image" alt="" />
          </div>
        </div>
        <div v-if="form.video" class="infos">
          <h1>播苗视频</h1>
          <video :src="form.video"></video>
        </div>
      </div>
      <div v-show="modluIndex == 11" class="list-item" style="height: 400px">
        <div class="info">
          <h1>地块名称</h1>
          <h1>{{ form.parcelName }}</h1>
        </div>
        <div class="info">
          <h1>地块坐标</h1>
          <h1>{{ form.longitude }}{{ form.latitude }}</h1>
        </div>
        <div class="info">
          <h1>地块海拔</h1>
          <h1>{{ form.elevation }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cateApi from "@/request/api/hegezhen";
import { ImagePreview } from "vant";

export default {
  data() {
    return {
      modluIndex: 0,
      form: {},
      list: [],
      seeShow: false,
      video: "",
      parcelName: "",
      channelNo: "",
    };
  },
  async mounted() {
    let item = this.$route.query.params;
    item = JSON.parse(item);
    this.modluIndex = item.modluIndex;
    let params = {
      batchId: item.batchId,
    };
    try {
      let res = await cateApi.getBymassif(params);
      this.parcelName = res.data.name;
    } catch (error) {
      console.log(error);
    }
    if (item.modluIndex == 1) {
      try {
        let res = await cateApi.getByBatchId(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 2) {
      try {
        let res = await cateApi.fertilizerBy(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 3) {
      try {
        let res = await cateApi.byHarvest(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 4) {
      try {
        let res = await cateApi.byCamera(params);
        if (!res.data) return;
        this.channelNo = res.data.channelNo;
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 5) {
      try {
        let res = await cateApi.cxparceloil(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 6) {
      params.pageNum = 1;
      params.pageSize = 100;
      params.productBatchId = item.batchId;
      try {
        let res = await cateApi.pharmacyList(params);
        this.list = res.data.list;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 7) {
      try {
        let res = await cateApi.byWeather(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 8) {
      try {
        let res = await cateApi.saplingBy(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 10) {
      try {
        let res = await cateApi.byIrrigate(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
    if (item.modluIndex == 11) {
      try {
        let res = await cateApi.byPosition(params);
        this.form = res.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    seeImgChange(item) {
      console.log(item);
      ImagePreview([item.image]);
    },
    async seeChange() {
      if (!this.channelNo) {
        this.$toast("无设备");
        return;
      }
      let params = {
        channelNo: this.form.channelNo,
        deviceSerial: this.form.deviceSerial,
      };
      try {
        let res = await cateApi.cameraFlv(params);
        this.video = res.msg;
        this.seeShow = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-top {
  height: 100%;

  .top-bg {
    position: relative;
    .bg {
      width: 100%;
      height: 5.5rem;
    }
    .main-con {
      position: absolute;
      left: 0.5rem;
      top: 1.28rem;
      h1 {
        font-weight: bold;
        font-size: 0.44rem;
        color: #ffffff;
      }
      .identifying {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.244rem;
        img {
          margin-right: 0.068rem;
        }
        span {
          color: #fff;
          margin-right: 0.18rem;
        }
      }
    }
  }

  .describes-con {
    padding: 0.08rem;
    margin-top: -2.444rem;
    position: relative;
  }
  .list-item {
    background-color: #fff;
    border-radius: 0.16rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    // margin-top: 20px;
    .info {
      line-height: 0.6rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      h1 {
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          color: #333;
          flex: 1;
          font-weight: 600;
        }
      }

      .result {
        color: #33bd7d !important;
      }
    }
    .infos {
      line-height: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      h1 {
        width: 20%;
      }
      img {
        width: 100%;
      }
    }
  }
  .list-con {
    background: linear-gradient(
      180deg,
      rgba(245, 255, 252, 0.8) 0%,
      #ffffff 7%,
      #f9fffd 100%
    );
    box-shadow: 0px -3px 12px 1px rgba(147, 181, 175, 0.1);
    border-radius: 10px 10px 10px 10px;
    border: 4px solid #ffffff;
    padding: 20px;
    margin-bottom: 40px;
    .top {
      margin-bottom: 16rpx;
    }
    .top span:nth-child(1) {
      font-size: 32px;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #15201d;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      line-height: 50px;
    }
  }
}
</style>
